import React from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "gatsby";
import FooterLogo from "../../images/footer-logo.webp";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

import { triggerCustomGTM } from "../../utils/helper";
import LazyLoad from "react-lazy-load";

const globalFootText = (copyRightYear) => (
    <>
        <p>
            WAKIX is a registered trademark and WAKIX for You is a trademark of
            Bioprojet Europe, Ltd.{" "}
        </p>
        <p>Harmony Biosciences name and logo are registered trademarks.</p>
        <p>&copy; {copyRightYear} Harmony Biosciences. All rights reserved.</p>
    </>
);

const HcpFooter = ({ footerText, jobCode, copyRightYear = "2024" }) => {
    return (
        <div className="centered-content">
            <div className="footer-wrapper">
                <Row className="row">
                    <Col lg={2} md={12}>
                        <a
                            href="https://www.harmonybiosciences.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="footer-logo"
                            aria-label="Harmony Biosciences logo"
                        >
                            <LazyLoad offset={300} debounce={false}>
                                <img
                                    src={FooterLogo}
                                    alt="Harmony Biosciences logo"
                                    width="149"
                                    height="117"
                                />
                            </LazyLoad>
                        </a>
                    </Col>
                    <Col lg={8}>
                        <div className="footer-desc">
                            <ul className="footer-menu">
                                <li className="first">
                                    <a
                                        href="https://www.harmonybiosciences.com/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        aria-label="Facebook"
                                    >
                                        About Harmony Biosciences
                                    </a>
                                </li>
                                <li>
                                    <Link to="/terms-of-use">Terms of Use</Link>
                                </li>
                                <li>
                                    <Link
                                        to="/privacy-policy"
                                        aria-current="page"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                                <li>
                                    <div className="social-links">
                                        <span className="no-underline">
                                            Follow Us
                                        </span>
                                        <a
                                            href="https://www.facebook.com/WAKIX"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="facebook-icon"
                                            aria-label="Follow us on Facebook"
                                            onClick={() =>
                                                triggerCustomGTM({
                                                    event: "gtm.click",
                                                    customTag:
                                                        "Follow Us Facebook",
                                                })
                                            }
                                        >
                                            <FaFacebookF
                                                alt="Follow us on Facebook icon"
                                                aria-hidden="true"
                                            />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/wakix_pitolisant/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="insta-icon"
                                            aria-label="Follow us on Instagram"
                                            onClick={() =>
                                                triggerCustomGTM({
                                                    event: "gtm.click",
                                                    customTag:
                                                        "Follow Us Instagram",
                                                })
                                            }
                                        >
                                            <FaInstagram
                                                alt="Follow us on Instagram icon"
                                                aria-hidden="true"
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                            <p>
                                {footerText
                                    ? footerText
                                    : globalFootText(copyRightYear)}
                                {jobCode ?? "US-WAK-2400251/July 2024"}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default function Footer({
    footerText,
    jobCode,
    copyRightYear,
    ...props
}) {
    return (
        <footer role="contentinfo" {...props}>
            <HcpFooter
                jobCode={jobCode}
                footerText={footerText}
                copyRightYear={copyRightYear}
            />
        </footer>
    );
}

Footer.propTypes = {};
