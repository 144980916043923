import React, { useState, useEffect } from "react";
import StickyISI from "../../sticky-isi";
import HcpIsi from "./isi-content";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Isi({ footerRef, callLinkNeeded, noStickyHCPLocatorCTA }) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Check if the URL has a hash and scroll to it after the component mounts
    const hash = window.location.hash;
    if (hash) {
      // Wait for all elements to be rendered
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, []);

  return (
    <StickyISI
      footerRef={footerRef}
      noStickyHCPLocatorCTA = {noStickyHCPLocatorCTA}
      stickyHeight={150}
      stickyStyles={{
        backgroundColor: "#ffffff",
        height: 150,
        width: "100%",
        boxShadow: "0px 3px 10px 5px #d8d8d8",
        paddingTop: 5,
      }}
      className="isi"
      onFooterStateChange={(result) => {
        setIsExpanded(result);
      }}
    >
      <div className="isi-content-wrapper">
        <div className="isi-content gutter-all">
          {!isExpanded && (
            <div className="content-container">
              <div className="centered-content relative">
                <div className="isi-anchor-container absolute">
                  <AnchorLink
                    href="#isi"
                    className="btn-expand-isi gutter-right"
                    offset={155}
                  >
                    <span className="up-arrow-symbol">+</span>
                  </AnchorLink>
                </div>
              </div>
            </div>
          )}
          <HcpIsi callLinkNeeded={callLinkNeeded} />
        </div>
      </div>
    </StickyISI>
  );
}
