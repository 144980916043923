import React from "react";
import useDeviceSize from "../../../hooks/use-device-size";

const FixedOrNormal = () => {
    return (
        <>
            <h2 className="isi-h2 fixed">
                Indications and Usage & <br className="show-xs" />
                Important Safety Information
            </h2>
            <h2 className="isi-h2 normal">Important Safety Information</h2>
        </>
    );
};

const IsiTitle = () => {
    const deviceSizes = useDeviceSize();
    return (
        <>
            {deviceSizes?.mdUp ? (
                <h2 className="isi-h2 first">Important Safety Information</h2>
            ) : (
                <FixedOrNormal />
            )}
        </>
    );
};

const IndicationsUsage = () => {
    return (
        <div className="indi-usage-block">
            <h2 className="isi-h2">Indications and Usage</h2>
            <span>WAKIX is a prescription medicine used to treat:</span>
            <ul className="bullet-list no-show-on-sticky">
                <li>
                    excessive daytime sleepiness (EDS) or cataplexy in adults
                    with narcolepsy.
                </li>
                <li>
                    excessive daytime sleepiness (EDS) in children 6 years of
                    age and older with narcolepsy.
                </li>
            </ul>
        </div>
    );
};

const renderPhoneNumber = (number, callLinkNeeded) => {
    return callLinkNeeded ? (
        <a href={`tel:${number}`}>{number}</a>
    ) : (
        <span className="non-link-number">{number}</span>
    );
};
export default function HcpIsi({ callLinkNeeded }) {
    const deviceSizes = useDeviceSize();

    return (
        <section className="content-container isi-section">
            <div className="centered-content isi-content">
                {deviceSizes?.mdUp ? <IndicationsUsage /> : <></>}
                <div className="isi-block">
                    <IsiTitle />
                    <p>
                        <strong>
                            Do not take WAKIX if you are allergic to pitolisant
                            or any ingredient in WAKIX, or if you have severe
                            liver disease.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            WAKIX can cause a change in the electrical activity
                            of the heart known as QT prolongation.
                        </strong>{" "}
                        This is a heart rhythm problem that can lead to an
                        abnormal heartbeat. You have a higher chance of getting
                        QT prolongation if you have certain heart or other
                        medical conditions, or if you take WAKIX with certain
                        medicines. Tell your healthcare provider right away if
                        you have a change in your heartbeat or if you feel dizzy
                        or faint while taking WAKIX.
                    </p>
                    <p>
                        <strong>
                            Tell your healthcare provider about all your medical
                            conditions,
                        </strong>{" "}
                        including if you have any heart, liver, or kidney
                        problems, or problems with blood levels of your
                        electrolytes, such as potassium or magnesium.
                    </p>
                    <p>
                        <strong>
                            Tell your healthcare provider about all the
                            medicines you take or plan to take,
                        </strong>{" "}
                        including prescription and over-the-counter medicines,
                        vitamins, and herbal supplements. Taking WAKIX with
                        certain medicines may cause side effects or affect the
                        way each other works.
                    </p>
                    <p>
                        Hormonal birth control methods may not work while taking
                        WAKIX. Use an alternative non-hormonal birth control
                        method during treatment with WAKIX and for at least 21
                        days after stopping WAKIX.
                    </p>

                    <p>
                        <strong>
                            Tell your healthcare provider if you are pregnant or
                            planning to become pregnant.
                        </strong>{" "}
                        You are encouraged to enroll in the WAKIX pregnancy
                        registry if you become pregnant while taking WAKIX. The
                        registry collects information about the health of you
                        and your baby. To enroll or obtain information from the
                        registry, call{" "}
                        <nobr>
                            {renderPhoneNumber(
                                "1-800-833-7460",
                                callLinkNeeded
                            )}
                            .
                        </nobr>
                    </p>
                    <p>
                        <strong>
                            The most common side effects of WAKIX in adults
                        </strong>{" "}
                        include insomnia, nausea, and anxiety.
                    </p>
                    <p>
                        <strong>
                            The most common side effects of WAKIX in children
                        </strong>{" "}
                        include headache and insomnia.
                    </p>
                    <p>
                        These are not all the possible side effects of WAKIX.
                        Call your healthcare provider for medical advice about
                        side effects.
                    </p>
                    <p>
                        It is not known if WAKIX is safe and effective to treat
                        excessive daytime sleepiness in children under 6 years
                        of age with narcolepsy or to treat cataplexy in people
                        under 18 years of age with narcolepsy.
                    </p>
                    <p>
                        You are encouraged to report negative side effects of
                        prescription drugs to the FDA. Visit{" "}
                        <a
                            href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.fda.gov/medwatch
                        </a>
                        , or call{" "}
                        <nobr>
                            {renderPhoneNumber(
                                "1-800-FDA-1088",
                                callLinkNeeded
                            )}
                            .
                        </nobr>{" "}
                        You can also report negative side effects to Harmony
                        Biosciences at{" "}
                        <nobr>
                            {renderPhoneNumber(
                                "1-800-833-7460",
                                callLinkNeeded
                            )}
                            .
                        </nobr>
                    </p>
                    {deviceSizes?.mdUp ? (
                        <p className="strong">
                            Please see accompanying{" "}
                            <a
                                href="/pdf/wakix-tablets-pi.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Full Prescribing Information
                            </a>
                            .
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
                {deviceSizes?.mdDown ? (
                    <>
                        <IndicationsUsage />
                        <p className="strong">
                            Please see accompanying{" "}
                            <a
                                href="/pdf/wakix-tablets-pi.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Full Prescribing Information
                            </a>
                            .
                        </p>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </section>
    );
}
